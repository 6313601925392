import styled from 'styled-components';
import Navbar from '../../general/navbar';
import styles from './css-modules/landing.module.css';
import USMAP from './usMap';
import { NarrowContent } from '../../wrappers';
import Title from './title';
import SerifTitle from './serifTitle';
import SplitScreen from '../../layout/splitscreen';
import { device } from '../../../utilities';
import Video from '../../../resources/video-c.mp4';
import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { Power1, Power2 } from 'gsap';

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    @media screen and (min-width: 500px) {
        min-height: 800px;
    }
    @media screen and (max-width: 500px) and (orientation: portrait) {
        height: ${window.innerHeight}px;
    }
    @media screen and (min-width: 500px) and (orientation: portrait) {
        height: ${window.innerHeight}px;
    }

    background: #fff;
    width: 100%;
    overflow: hidden;
`;

function Landing({ title, desc }) {
    const titleRef = useRef(null);
    const serifTitle = useRef();
    const map = useRef();
    const powered = useRef();
    const buttRef = useRef();

    useEffect(() => {
        gsap.fromTo( titleRef.current, { opacity: 0, y: -15 },{ opacity: 1, y: 0, duration: 0.6, delay: 0.1 });
        gsap.fromTo( map.current, { opacity: 0, y: '-8.5vw' },{ opacity: 1, y: '-10vw', duration: 1.1, delay: 0.45, ease: Power1.easeInOut });
        gsap.fromTo( serifTitle.current, { opacity: 0, y: 15 },{ opacity: 1, y: 0, duration: 0.7, delay: 0.4 });
        gsap.fromTo( powered.current, { opacity: 0, y: -10 },{ opacity: 1, y: 0,  duration: 0.7, delay: 0.9 });
        gsap.fromTo( buttRef.current, { y: 40 },{ y: 0, duration: 0.9, delay: 0.6, ease: Power2.easeOut })
    }, []);

    return (
        <Container>
            <Navbar />
            <div style={{position: 'relative', zIndex: 1, display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                <NarrowContent style={{ flexGrow: 1 }}>
                    <div className={styles.contentContainer}>
                        <Title {...{ title }} innerRef={titleRef}/>
                        <div className={styles.mapContainer} ref={map}>
                            <div className={styles.map}>
                                <USMAP vid={Video} />
                            </div>
                            <div className={styles.map}>
                                <USMAP vid={Video} />
                            </div>
                            <div className={styles.map}>
                                <USMAP vid={Video} />
                            </div>
                        </div>
                        <SerifTitle title={'& Climate Change'} innerRef={serifTitle}/>

                        <div className={styles.poweredContainer} ref={powered}>
                            <SplitScreen justified justify='space-between' breakpoint={device.mobileL} rightWeight={2}>
                                <div>
                                    <p>Scroll down for the story</p>
                                </div>
                                <p className={styles.georgia}>Powered by Google’s Data Commons</p>
                            </SplitScreen>
                        </div>

                        <button style={{zIndex: '2'}} ref={buttRef} onClick={() => window.open('https://docs.google.com/document/d/19QCR5fO_gcse8CN_tSGfkrofGNEo_kpK8Fe0TGBn0S8/')} className={styles.button}><b>About The Project</b></button>

                    </div>
                </NarrowContent>
            </div>
        </Container>
    );
}

export default Landing;
