import { scaleLinear } from "d3";
import { attrs } from "./multiAttrsStyles";

const mapAnimFrames = {
  /* PROFESSOR, in case you want to update this, please also update mapPaths function in MapFrames.js */

  frame0: (vizSelections) => {
    const { mapPaths, statePaths } = vizSelections;

    let colScaleInsecure = scaleLinear()
      .domain([0, 0.35])
      .range(["#D4E9FF", "#003966"]);

    mapPaths
      .transition()
      .duration(750)
      .call(attrs, {
        fill: (d) => {
          let props = d.properties;
          let rate = null;
          if (props.popInsecure.value && props.popEstimate.value) {
            rate = props.popInsecure.value / props.popEstimate.value;
          }
          return rate ? colScaleInsecure(rate) : "#bdbdbd";
        },
        "fill-opacity": 1,
      });

    statePaths.transition().duration(750).call(attrs, {
      "stroke-width": "0px",
    });

    mapPaths.style("pointer-events", "auto");
  },

  frame1: (vizSelections) => {
    const { mapPaths, statePaths } = vizSelections;

    let colScaleInsecure = scaleLinear()
      .domain([0, 0.35])
      .range(["#D4E9FF", "#003966"]);

    mapPaths
      .transition()
      .duration(750)
      .call(attrs, {
        fill: (d) => {
          let props = d.properties;
          let rate = null;
          if (props.popInsecure.value && props.popEstimate.value) {
            rate = props.popInsecure.value / props.popEstimate.value;
          }
          let col = "#eee";
          return rate && rate > 0.1125
            ? (col = colScaleInsecure(rate))
            : (col = "#f0f0f0");
        },
        "fill-opacity": 1,
      });

    function filterFunc(invert = false) {
      return function (d) {
        const props = d.properties;
        const rate = props.popInsecure.value / props.popEstimate.value;

        const logical = rate > 0.1125;

        return invert ? !logical : logical;
      };
    }

    mapPaths.filter(filterFunc(true)).style("pointer-events", "none");
    mapPaths.filter(filterFunc(false)).style("pointer-events", "auto");

    statePaths.transition().duration(750).call(attrs, {
      "stroke-width": "0px",
    });
  },

  frame2: (vizSelections) => {
    const { mapPaths, statePaths } = vizSelections;

    let colScaleInsecure = scaleLinear()
      .domain([0, 0.35])
      .range(["#D4E9FF", "#003966"]);

    let stateIDs = [];

    mapPaths
      .transition()
      .duration(750)
      .call(attrs, {
        fill: (d) => {
          let props = d.properties;
          let rate = null;
          let cover = props.farmCover.value;
          if (
            props.popInsecure.value &&
            props.popEstimate.value &&
            cover > 0.75
          ) {
            rate = props.popInsecure.value / props.popEstimate.value;
            if (!stateIDs.includes(d.id.slice(0, 2)) && rate > 0.1125) {
              stateIDs.push(d.id.slice(0, 2));
            }
          }
          return rate && rate > 0.1125 ? colScaleInsecure(rate) : "#f0f0f0";
        },
      });

    function filterFunc(invert = false) {
      return function (d) {
        const props = d.properties;
        const cover = props.farmCover.value;
        const rate = props.popInsecure.value / props.popEstimate.value;

        const logical = cover > 0.75 && rate > 0.1125;

        return invert ? !logical : logical;
      };
    }

    mapPaths.filter(filterFunc(true)).style("pointer-events", "none");
    mapPaths.filter(filterFunc(false)).style("pointer-events", "auto");
    //912

    statePaths
      .transition()
      .duration(750)
      .call(attrs, {
        "stroke-width": (d) => {
          let stroke = "0px";
          if (stateIDs.includes(d.id)) {
            stroke = "2px";
          }
          return stroke;
        },
      });
  },
};

export default mapAnimFrames;
