import { useRef, useEffect, useState } from "react";
import { appendMapCanvas, removeMapCanvas } from "./d3-functions/mapCanvas";
import {
  appendStatePaths,
} from "./d3-functions/mapParts";
import { usTopoFeatures } from "./data-js-files/usTopoFeatures";
import { stateTopoFeatures } from "./data-js-files/stateTopoFeatures";

import { attrs } from "./d3-functions/multiAttrsStyles";

import { scaleLinear } from "d3";
import {
  appendDiscColorLegend,
} from "./d3-functions/legends";

import { orgs } from "./data-js-files/orgs.min";

import { stateAbbrs } from "./data-js-files/archive/state_abbr";
import { stateFips } from "./data-js-files/archive/state_fips";

import { select } from "d3";

export default function HeatMap({ orgType, setOverlayTrigger, setOverlayContent }) {
  const [vizSelections, setVizSelections] = useState(null);

  const [currData, setCurrData] = useState(null);

  const appRef = useRef();

  useEffect(() => {
    const mapCanvas = appendMapCanvas({
      parentRef: appRef.current,
      containerClassName: "mapContainer",
      containerWidth: "100%",
      viewBoxWidth: 975,
      viewBoxHeight: 640,
      topoFeatures: usTopoFeatures,
      stateTopoFeatures: stateTopoFeatures,
    });

    const statePaths = appendStatePaths(
      mapCanvas,
      {
        groupId: "usStatePathsHeatMap",
      },
      {
        stroke: "#fff",
        fill: "#eee",
        "stroke-width": "1px",
      }
    );

    const farmLegend = appendDiscColorLegend(mapCanvas, {
      positionX: 700,
      positionY: 25,
      colorScaleFunc: scaleLinear().domain([0, 100]).range(["#D4E9FF", "#003966"]),
      legendTitle: 'Count',
      scaleVal: 0.7
    }); 

    setVizSelections({ statePaths, farmLegend });

    return () => removeMapCanvas(appRef.current);
  }, []);

  useEffect(() => {
    let data = {};

    Object.keys(orgs).forEach((key) => {
      data[key] = orgs[key][orgType];
    });

    setCurrData(data);
  }, [orgType])

  useEffect(() => {
    if (vizSelections) {
      let scale = scaleLinear().domain([0, 100]).range(["#D4E9FF", "#003966"]);

      const { statePaths } = vizSelections;

      statePaths.call(attrs, {
        title: (d) => stateFips[d.id],
      });

      statePaths.transition().duration(600).call(attrs, {
        fill: (d) =>{ 
          let data = currData[stateAbbrs[stateFips[d.id]]];
          return data ? scale(data.length) : '#fff';
        },
        stroke: "#fff",
      });

      statePaths.on('mouseover', function(d) {
        if (currData[stateAbbrs[stateFips[this.id.slice(2,4)]]]) {
          select(this).raise().style('stroke', '#000').style('cursor', 'pointer');
        }
      });
      statePaths.on('mouseout', function(d) {
          select(this).style('stroke', '#fff').style('cursor', 'auto');
      });

      statePaths.on('click', function(d) {
        if (currData[stateAbbrs[stateFips[this.id.slice(2,4)]]]) {
          setOverlayTrigger(true);
          let stateID = this.id.slice(2,4);
          let data = {
            id: stateID,
            data: currData[stateAbbrs[stateFips[stateID]]]
          }
          setOverlayContent(data);
        }
      });
    }
  }, [currData]);


  return (
    <>
      <div id="heat-map" style={{marginTop: '50px'}}>
        <div
          className="vizContain"
          ref={appRef}
          style={{ position: "relative" }}
        >
        </div>
      </div>
    </>
  );
}
