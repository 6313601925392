

export const collaborators = [{
    name: 'TechSoup Global Network',
    link: 'https://www.techsoup.org/',
},{
    name: 'Plotree Info Design',
    link: 'https://plotree.studio/',
}];

export const chapterList = [{
    title: 'About the Project',
    handler: () => window.open('https://docs.google.com/document/d/19QCR5fO_gcse8CN_tSGfkrofGNEo_kpK8Fe0TGBn0S8'),
},{
    title: "Google's Data Commons",
    handler: () => window.open('https://datacommons.org/'),
},{
    title: 'TechSoup Global Network',
    handler: () => window.open('https://www.techsoup.org/'),
}];

export const climateMap = [
    <>
        <h3>The United States is one of the largest food producing countries</h3>
        <p>The US is one of the largest food producing countries in the world, with many people whose livelihood is dependent on farming. <b>The central states have the most farmland cover, as illustrated in dark green.</b></p>
    </>,
    <>
        <p>With global warming, temperatures throughout the world are rapidly rising. <b><a style={{color: 'unset'}} href="https://www.epa.gov/climate-indicators/climate-change-indicators-us-and-global-temperature#ref2" target="_blank" rel="noreferrer">According to the EPA</a> the global average surface temperature has risen at a rate of 0.17° F every decade since 1901</b>. Climate models are useful tools to project future climate change under different mitigation scenarios.</p>
    </>,
    <>
        <h3>As temperatures increase, our food production environments will potentially face newer challenges.</h3>
        <p>This map is based on the estimated temperature results from the CMIP6 modeling ensemble, under the SSP245 scenario. <b>This scenario assumes some climate protection measures will be taken and is considered a medium estimate.</b></p>
        <p className='second_para'><b>The map shows the predicted difference in the average yearly maximum temperatures between 1980-2010 and 2040-2050</b>. For more info on climate change models see our <a href="https://docs.google.com/document/d/19QCR5fO_gcse8CN_tSGfkrofGNEo_kpK8Fe0TGBn0S8/edit" target="_blank" rel="noreferrer" style={{color: 'unset'}}>about page</a>.</p>
    </>,
    <>
        <p>To illustrate <b>farming regions in the US which may be most impacted by climate change</b>, the map now highlights counties with more than 50% farm cover that have extreme projected temperature differences (increase of more than 4 degrees or decrease of more than 2 degrees Celsius).</p>
        <p className='second_para click_prompt_map'><b>Click on a highlighted county to explore further in Data Commons.</b></p>

    </>,
];

export const insecureMap = [
    <>
        <h3>Food Insecurity in the United States.</h3>
        <p>Here is the rate of <b>food insecurity by county</b> in the United States. <b>Food insecurity is more prevalent among children</b> than it is among the total population in every state and in more than 8 out of 10 counties, according to Feeding America’s 2023 <a href="https://www.feedingamerica.org/research/map-the-meal-gap/overall-executive-summary" target="_blank" rel="noreferrer" style={{color: 'unset'}}>“Map the Meal Gap” report</a>.</p></>,
    <>
        <h3>Counties with Food Insecurity higher than the national average </h3>
        <p>The map now highlights counties with higher than average food insecurity in the US. According to <a target="_blank" rel="noreferrer" style={{color: 'unset'}} href="https://www.feedingamerica.org/research/map-the-meal-gap/overall-executive-summary">Feeding America</a>, <b>8 out of 10 counties with high food insecurity are in the South, and 9 out of 10 are rural.</b></p>
    </>,
    <><p>Now the map highlights counties with more than average food insecurity and where farm cover is more than 75%. <b>This encompasses 912 counties in the United States with approximately 3 million people facing food insecurity</b>. In any county, the prevalence of farming is not a guarantee of food security.</p></>,
];

export const orgTypes = [
    "Animal Husbandry",
  //  "Farm Bureaus & Granges",
    "Food Banks & Pantries",
    "Food Programs",
    "Food, Agriculture & Nutrition",
    "Meals on Wheels",
    "Congregate Meals",
    "Farmland Preservation",
    "Nutrition",
    "Soup Kitchens",
   // "Home Economics",
   // "Other Vegetable (except Potato) and Melon Farming",
   // "Fruit and Vegetable Markets",
   // "Soil Preparation, Planting, and Cultivating"
]