import styled from 'styled-components';
import { useEffect, useRef } from "react";
import PropTypes from 'prop-types';

const Container = styled.div`
    position: relative;
    width: fit-content;
    height: fit-content;
`;


function ReactIntersectionObserver({ children, ratio, options, callback }) {
    const element = useRef();
    useEffect(() => {
        const io = new IntersectionObserver((entry) => {
            if (entry[0].intersectionRatio > ratio) {
                callback();
            } 
        }, {
            ...options 
        });
        io.observe(element.current);
    }, [])

    return (
        <Container ref={element}>
            {children}
        </Container>
    )
};

ReactIntersectionObserver.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]).isRequired,
    ratio: PropTypes.number.isRequired,
    options: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired
}

ReactIntersectionObserver.defaultProps = {
    ratio: 0,
    options: { threshold: 1.0 },
    callback: function() { console.log('callback') }
};

export default ReactIntersectionObserver;