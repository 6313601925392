import { attrs, styles } from "./multiAttrsStyles";

function appendRegionPaths(
  selection,
  { groupId = null } = {},
  attrsObj = {},
  stylesObj = {}
) {
  const { topoFeatures, geoPath } = selection.mapProps;
  // const { setPosX, setPosY, setTtDisplay, setTtData } = stateSetters;

  const mapG = selection.append("g");

  if (groupId) {
    mapG.attr("id", groupId);
  }

  const regionPaths = mapG
    .selectAll(`path.region`)
    .attr("classed", "counties", true)
    .data(topoFeatures)
    .join("path")
    .attr("id", (d) => d.id)
    .attr("d", (d) => geoPath(d))
    .attr("class", "region")
    .attr("fill", "black")
    .attr(
      "transform-origin",
      (d) =>
        `${geoPath.centroid(d.geometry)[0]} ${geoPath.centroid(d.geometry)[1]}`
    )
    .call(attrs, attrsObj)
    .call(styles, stylesObj)

  regionPaths.mapProps = selection.mapProps;

  return regionPaths;
}

//I am sure there is a better way to do this. For working purposes, the function is duplicated
function appendStatePaths(
  selection,
  { groupId = null } = {},
  attrsObj = {},
  stylesObj = {}
) {
  const { stateTopoFeatures, geoPath } = selection.mapProps;

  const mapG = selection.append("g");

  if (groupId) {
    mapG.attr("id", groupId);
  }

  const statePaths = mapG
    .selectAll(`path.region`)
    .data(stateTopoFeatures)
    .join("path")
    .attr("id", (d) => "id" + d.id)
    .attr("d", (d) => geoPath(d))
    .attr("class", "region")
    .classed("state", true)
    .attr("fill", "black")
    .attr(
      "transform-origin",
      (d) =>
        `${geoPath.centroid(d.geometry)[0]} ${geoPath.centroid(d.geometry)[1]}`
    )
    .call(attrs, attrsObj)
    .call(styles, stylesObj);

  return statePaths;
}

function appendRegionPoints(
  selection,
  { groupId = null, pointRadius = 0 } = {},
  attrsObj = {},
  stylesObj = {}
) {
  const { topoFeatures, primaryID, geoPath } = selection.mapProps;

  const mapPointsG = selection.append("g");

  if (groupId) {
    mapPointsG.attr("id", groupId);
  }

  const regionPoints = mapPointsG
    .selectAll(`circle.mapCircle`)
    .data(topoFeatures)
    .join("circle")
    .attr("cx", (d) => d.center[0])
    .attr("cy", (d) => d.center[1])
    .attr("r", pointRadius)
    .attr("class", "mapCircle")
    .attr("id", (d) => d.properties[primaryID])
    .attr("fill", "white")
    .call(attrs, attrsObj)
    .call(styles, stylesObj);

  return regionPoints;
}

export { appendRegionPaths, appendRegionPoints, appendStatePaths };
