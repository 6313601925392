import styled from 'styled-components';
import PropTypes from 'prop-types';

const Container = styled.div`
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

function Sticky({children}) {
    return (
        <Container>
            {children}
        </Container>
    )
}

Sticky.propTypes = {
    children: PropTypes.element.isRequired
}

export default Sticky;