import styled from 'styled-components';
import PropTypes from 'prop-types';

function ChapterTitle({
    title,
    number,
    color,
    sub
}) {
    return (
        <Container $fill={color} $font={!sub ? "Replica-Bold" : "Georgia"}>
            <h1>Q{number}</h1>
            <h3>{title}</h3>
        </Container>
    );
}

ChapterTitle.propTypes = {
    // By default all props are optional. Please add the isRequired bool if you need them to be mandatory.
    title: PropTypes.string.isRequired,
    number: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
    sub: PropTypes.bool.isRequired
}

ChapterTitle.defaultProps = {
    title: 'This is a Chapter Title - Mexico DC2',
    color: '#000',
    number: 1,
    sub: false
}

const Container = styled.div`
    display: flex;
    align-items: center;
    color: ${props => props.$fill};
    margin: 15px 0px;

    h1 {
        font-family: "Replica-Bold", sans-serif;
        font-size: clamp(3rem, 2rem + 2.095vw, 8rem);
        margin-right: 15px;
        color: #00BAB5;
        border-bottom: 5px solid #00BAB5;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    h3 {
        font-family: "Replica-Light", sans-serif;
        font-size: clamp(1.1rem, 0.814rem + 2.095vw, 1.80rem);
    }

`

export default ChapterTitle;