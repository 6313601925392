import styled from "styled-components";

const TooltipContain = styled.div`
  position: fixed;
  top: ${(props) => {
    const { $posY: posY, $yOffset: yOffset } = props;
    const top = yOffset ? posY + yOffset : posY;
    return `${top}px`;
  }};
  left: ${(props) => {
    const { $posX: posX, $width: width } = props;
    const left = width ? posX - width / 2 : posX;
    return `${left}px`;
  }};
  transform: ${(props) => {
      const { $posX: posX, $width: width, $posY: posY } = props;
      let xTrans = '0px'; 
      let yTrans = '0px';
      if (posX > window.innerWidth/1.5) {
        xTrans = `${-width*0.5}px`;
      }
      if (posY > window.innerHeight/1.5) {
        yTrans = `${-width * 0.9}px`;
      }
      return `translate(${xTrans}, ${yTrans})`;
  }};
  height: ${(props) => `${props.$height}px`};
  width: ${(props) => `${props.$width}px`};
  background-color: #fff;
  display: ${(props) => props.$display};
  padding: 10px 15px;
  border-radius: 8px;
  box-shadow: 10px 10px 57px -23px rgba(0,0,0,0.38);
`;

const TooltipWrapper = ({
  posX,
  posY,
  yOffset,
  width,
  height,
  display,
  children,
}) => {
  return (
    <TooltipContain
      $posX={posX}
      $posY={posY}
      $yOffset={yOffset}
      $width={width}
      $height={height}
      $display={display}
    >
      {children}
    </TooltipContain>
  );
};

export default TooltipWrapper;
