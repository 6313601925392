import styled from 'styled-components';
import { device } from "../../utilities";
import Powered from './poweredBy';

const Container = styled.div`
    padding: 15px;
    box-sizing: border-box;
    border-left: 5px solid ${props => props.$color};
    margin-left: 20px;
    border-radius: 15px;
    color: ${props => props.$textColor};

    @media ${device.$mobileL} {
        border-radius: 0px;
    }

    h3 {
        margin-top: 0px;
        font-family: 'Georgia-Italic';

        @media screen and (min-width: 1600px) {
            font-size: 1.5em;
            line-height: 1.6em;
        }

        @media (max-width: 400px) {
            font-size: 1.1em;
        }
    }

    i {
        font-family: 'Georgia-Bold-Italic';
    }

    .second_para {
        margin-top: 10px;
    }

    .prompt {
        margin-top: 20px;
        font-size: 0.8em;
    }

    .mobile {
        display: none;
    }

    @media (max-width: 1150px) {
        width: 90%;
        margin-right: 10%;
    }
    @media (max-width: 1023px) {
        width: 80%;
        margin-left: 10%;
        background-color: rgba(255,255,255, 0.8);
        color: #000;
        border: 0px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    @media (max-width: 500px) {
        width: 90%;
        margin-left: 5%;
    }

    @media (max-width: 1023px) {
        .mobile {
            display: block;
            margin-top: 10px;
            font-size: 0.9em;

            a {
                color: black;
            }

            div {
                justify-content: flex-start !important;
            }
        }
    }
`

export default function IoRenderer({ data, fill, text, source }) {
    return (
        <Container $color={'#00BAB5'} $textColor={'#000'}>
            {data}
            <div className='mobile'>
                <p className='source'>Source: {source}</p>
                <Powered/>
            </div>
        </Container>
    )
}