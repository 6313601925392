import styled from 'styled-components';
import { device } from '../../../utilities';

const Heading = styled.h1`
    font-family: 'Replica-Bold', sans-serif;
    margin: 0px;
    color: #000;
    line-height: 90%;
    font-weight: 400;
    letter-spacing: -0.25rem;
    font-size: clamp(4.5rem, 2.6rem + 9.5vw, 14rem);
    z-index: 2;
    position: relative;
    
    @media ${device.fold} {
        span {
            display: block;
            margin-left: -1em;
            text-indent: 1.8em;
        }
    }

    @media screen and (max-width: 800px) {
        align-self: flex-start;
    }
`;

function Title({
    title,
    innerRef
}) {

    return (
        <Heading ref={innerRef}>{title}</Heading>
    )
}

export default Title;