import { useRef } from 'react';
import styled from 'styled-components';
import { stateFips } from '../data-js-files/archive/state_fips';

function Org({ org, content }) {
    return (
        <Organization>
            <h4 onClick={() => window.open(`https://www.google.com/search?q=${org.NAME.replaceAll(" ",'+').replaceAll("&", "%26")}+${stateFips[content.id]}`)}>{org.NAME.toLowerCase()}</h4>
        </Organization>
    )
}

function OverlayContent ({ content, orgType }) {
    const element = useRef();

    return (
        <>
            {content && <Container ref={element}>
                <StateTitle onClick={() => window.open('https://datacommons.techsoup.org/topic/foodsecurity/geoId/' + content.id)}>
                    {stateFips[content ? content.id : '01']}
                </StateTitle>
                <OrganizationType>
                    {orgType}
                </OrganizationType>

                <Organizations>
                    {content.data.map((org, index) => {
                        return <Org key={'org' + index} {...{org, content}}/>
                    })}
                </Organizations>
            </Container>}
        </>
    );
}

const Container = styled.div`
`;

const StateTitle = styled.h2`
    font-family: Georgia-Bold-Italic, 'serif';
    font-weight: 400;
    font-size: 3em;
    cursor: pointer;

    @media screen and (max-width: 650px) {
        font-size: 2.5em;
    }
`;

const OrganizationType = styled.h3`
    font-family: 'Replica-Bold', 'sans-serif';
    font-weight: 400;
    font-size: 1.5em;

    @media screen and (max-width: 650px) {
        font-size: 1.25em;
    }
`;

const Organizations = styled.div`
`;

const Organization = styled.div`
    font-family: 'Replica-Light', 'sans-serif';
    font-weight: 400;
    font-size: 1.1em;
    cursor: pointer;
    position: relative;
    width: fit-content;
    text-transform: capitalize;

    &:before {
        content: '';
        position: absolute;
        top: 100%;
        left: 0px;
        display: flex;
        width: 25px;
        height: 5px;
        background-color: #00bab5;
        z-index: -1;
        transform: scale(0);
        transition: 0.25s ease;
        transform-origin: left;
    }

    &:hover {
        &:before {
            transform: scale(1);
        }
    }

    @media screen and (max-width: 650px) {
        font-size: 1em;
    }
`

OverlayContent.propTypes = {

}

OverlayContent.defaultProps = {

}

export default OverlayContent;