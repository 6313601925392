import styled from "styled-components";
import Sticky from "./stickyElement";
import { device } from "../../utilities";
import PropTypes from "prop-types";
import React from "react";
import Powered from "../general/poweredBy";

const Container = styled.div`
  position: relative;
  display: flex;
  z-index: 2;
  flex-direction: column;

  @media ${(props) => props.$breakpoint} {
    flex-direction: ${(props) =>
    props.$direction === "forward" ? "row" : "row-reverse"};
  }
`;

const Left = styled.div`
  position: relative;
  width: 100%;
  padding: 0em 7em;

  @media ${(props) => props.$mobileL} {
    padding: 0em;
  }

  @media ${(props) => props.$breakpoint} {
    flex: ${(props) => props.$weight};
    width: unset;
    z-index: 3;
  }
`;

const Right = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 2;

  @media ${(props) => props.$breakpoint} {
    position: relative;
    flex: ${(props) => props.$weight};
    width: unset;
    z-index: 3;
  }

  .absolute {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.85em;
    position: absolute;
    bottom: 20px;
    width: 100%;

    @media screen and (max-width: 500px) {
      flex-direction: column;
      justify-content: unset;
      align-items: flex-start;
      gap: 5px;
    }

    a {
      color: black;
    }
  }

  .clickPrompt {
    position: absolute;
    top: 20px;
    font-family: 'Georgia-Italic';
    font-size: 0.78em;
    color: #555;

    @media screen and (min-width: 1600px) {
      font-size: 1.05em;
    }

    @media screen and (max-width: 1370px) and (max-height: 650px) {
      font-size: 0.72em;
    }
  }

  @media screen and (max-width: 1023px) {
      .clickPrompt {
        display: none;
      }
      .absolute {
        display: none;
      }
  }
`;

function StickyVis({ children, l_weight, r_weight, breakpoint, direction, source }) {
  const [list, vis] = children;

  return (
    <Container $breakpoint={breakpoint} $direction={direction}>
      <Left $weight={l_weight}>{list}</Left>
      <Right $weight={r_weight} $breakpoint={breakpoint}>
        <Sticky>
          <>
            <>{vis}</>
            <div className="absolute">
              <p>Source: {source}</p>
              <Powered />
            </div>
            <p className="clickPrompt">To explore food security issues at a local level in Data Commons, click on any highlighted county on the map.</p>
          </>
        </Sticky>
      </Right>
    </Container>
  );
}

StickyVis.propTypes = {
  direction: PropTypes.string.isRequired,
  breakpoint: PropTypes.string.isRequired,
  r_weight: PropTypes.number.isRequired,
  l_weight: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.node),
  source: PropTypes.element.isRequired
};

StickyVis.defaultProps = {
  direction: "forward",
  breakpoint: device.laptop,
  r_weight: 1.75,
  l_weight: 1,
  source: <a href='https://datacommons.org/' target='_blank' rel='noreferrer'>Data Commons</a>
};

export default StickyVis;
