import styled from 'styled-components';

export const ViewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 150px;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: auto;
    padding: 0px 4vw;
`;

export const NarrowContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px var(--navPadding);
`;

export const NavContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px var(--navPadding);
`;

export const ChapterSection = styled.div`
    display: flex;
    flex-direction: column;
    padding: clamp(30px, 5%, 50px);
`

export const TextContainer = styled.div`
    padding: 0px calc(var(--textPadding) + var(--paddingSide));
    color: var(--textColor);
    max-width: 800px;
    margin: auto;

    .para {
        padding: 25px;
        border: 1px dashed;
        border-radius: 15px;
    }

    p {
        margin: 1em 0px;
        line-height: 140%;
    }

    .link {
        font-family: 'Georgia-Bold-Italic', serif;
        font-size: 0.95em;
        color: black;
        text-decoration: none;
        position: relative;

        &:before {
            content: '';
            display: flex;
            width: 25px;
            height: 4px;
            transform: scale(0);
            background-color: #00bab5;
            position: absolute;
            bottom: -7px;
            transition: 0.25s ease;
            transform-origin: left;
        }

        &:hover {
            &:before {
                transform: scale(1);
            }
        }
    }
`;

export const BorderedText = styled.div`
    padding: 0.75em 1.75em;
    background-image: linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(90deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%), linear-gradient(0deg, silver 50%, transparent 50%);
    background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
    background-size: 15px 2px, 15px 2px, 2px 15px, 2px 15px;
    background-position: left top, right bottom, left bottom, right   top;
    animation: border-dance 1s infinite linear;
  }
  @keyframes border-dance {
    0% {
      background-position: left top, right bottom, left bottom, right   top;
    }
    100% {
      background-position: left 15px top, right 15px bottom , left bottom 15px , right   top 15px;
    }
  }
`;

export const ChartSection = styled.div`
    padding-left: clamp(3em, 14vw, 18em);
    padding-right: clamp(3em, 14vw, 18em);

    @media screen and (max-width: 950px) {
        padding-left: 1em;
        padding-right: 1em;
    }
`;

export const GraySection = styled.div`
    background-color: var(--grayOpaque);
`

export const TextCenter = styled.div`
    text-align: center;
`
