import {
  scaleLinear,
} from "d3";
import { attrs } from "./multiAttrsStyles";

const mapAnimFrames = {
  frame0: (vizSelections) => {
    const { mapPaths, statePaths, farmLegend, tempLegend2, tempLegend1, mapCanvas } =
      vizSelections;

    const { stateSetters } = mapCanvas.mapProps;
    const { setMetricFocus } = stateSetters;
    setMetricFocus("Farm");
    
    let farmCover = scaleLinear().domain([0, 1]).range(["#C6FF00", "#388E3C"]);

    farmLegend.transition().duration(400).style("fill-opacity", 1);

    tempLegend2.transition().duration(400).style("fill-opacity", 0);

    tempLegend1.transition().duration(400).style("fill-opacity", 0);

    mapPaths
      .transition()
      .duration(400)
      .call(attrs, {
        fill: (d) => {
          let cover = d.properties.farmCover.value;
          return cover ? farmCover(cover) : "#bdbdbd";
        },
        stroke: "#fff",
      });

    statePaths.transition().duration(400).call(attrs, {
      "stroke-width": "0px",
    });

    mapPaths.style("pointer-events", "auto");
  },

  /* PROFESSOR, in case you want to update this, please also update mapPaths function in MapFrames.js */

  frame1: (vizSelections) => {
    const {
      mapPaths,
      statePaths,
      farmLegend,
      tempLegend2,
      tempLegend1,
      mapCanvas,
    } = vizSelections;
    const { stateSetters } = mapCanvas.mapProps;
    const { setMetricFocus } = stateSetters;
    setMetricFocus("Temp");

    let coldScale = scaleLinear().domain([0, -8]).range(["#F1F8E9", "#283593"]);
    let hotScale = scaleLinear().domain([0, 8.5]).range(["#F1F8E9", "#B71C1C"]);

    farmLegend.transition().duration(400).style("fill-opacity", 0);

    tempLegend2.transition().duration(400).style("fill-opacity", 1);

    tempLegend1.transition().duration(400).style("fill-opacity", 1);

    mapPaths
      .transition()
      .duration(400)
      .call(attrs, {
        fill: (d) => {
          let temp = d.properties.tempForecastCMIP6_245.value;
          let col = "#bdbdbd";
          temp && temp > 0 ? (col = hotScale(temp)) : (col = coldScale(temp));
          return col;
        },
        stroke: "#fff",
      });

    statePaths.transition().duration(400).call(attrs, {
      "stroke-width": "0px",
    });

    mapPaths.style("pointer-events", "auto");
  },

  frame2: (vizSelections) => {
    const { mapPaths, statePaths, mapCanvas } = vizSelections;
    const { stateSetters } = mapCanvas.mapProps;
    const { setMetricFocus } = stateSetters;
    setMetricFocus("Temp");

    let coldScale = scaleLinear().domain([0, -8]).range(["#F1F8E9", "#283593"]);
    let hotScale = scaleLinear().domain([0, 8.5]).range(["#F1F8E9", "#B71C1C"]);

    mapPaths
      .transition()
      .duration(400)
      .call(attrs, {
        fill: (d) => {
          let temp = d.properties.tempForecastCMIP6_245.value;
          let col = "#bdbdbd";
          temp && temp > 0 ? (col = hotScale(temp)) : (col = coldScale(temp));
          return col;
        },
        stroke: "#fff",
      });

    statePaths.transition().duration(400).call(attrs, {
      "stroke-width": "0px",
    });

    mapPaths.style("pointer-events", "auto");
  },

  frame3: (vizSelections) => {
    const { mapPaths, statePaths, mapCanvas } = vizSelections;
    const { stateSetters } = mapCanvas.mapProps;
    const { setMetricFocus } = stateSetters;
    setMetricFocus("Both");
    let coldScale = scaleLinear().domain([0, -8]).range(["#F1F8E9", "#283593"]);
    let hotScale = scaleLinear().domain([0, 8.5]).range(["#F1F8E9", "#B71C1C"]);

    let stateIDs = [];

    mapPaths
      .transition()
      .duration(400)
      .call(attrs, {
        fill: (d) => {
          let temp = d.properties.tempForecastCMIP6_245.value;
          let cover = d.properties.farmCover.value;

          let col = "#bdbdbd";
          if (temp) {
            col = "#f0f0f0";
            if (cover > 0.5 && temp > 4) {
              if (!stateIDs.includes(d.id.slice(0, 2))) {
                stateIDs.push(d.id.slice(0, 2));
              }
              col = hotScale(temp);
            } else if (cover > 0.5 && temp < -2) {
              if (!stateIDs.includes(d.id.slice(0, 2))) {
                stateIDs.push(d.id.slice(0, 2));
              }
              col = coldScale(temp);
            }
          }
          return col;
        },
        stroke: "#fff",
      });

    function filterFunc(invert = false) {
      return function (d) {
        const temp = d.properties.tempForecastCMIP6_245.value;
        const cover = d.properties.farmCover.value;

        const logical = (cover > 0.5 && temp > 4) || (cover > 0.5 && temp < -2);

        return invert ? !logical : logical;
      };
    }

    mapPaths.filter(filterFunc(true)).style("pointer-events", "none");

    statePaths
      .transition()
      .duration(400)
      .call(attrs, {
        "stroke-width": (d) => {
          let stroke = "0px";
          if (stateIDs.includes(d.id)) {
            stroke = "2px";
          }
          return stroke;
        },
      });
  },
};

export default mapAnimFrames;

/* 

Frame 4/5 Choropleth

  frame4: (vizSelections) => {
    const { mapPaths, mapPoints } = vizSelections;
    const colorScale = scaleLinear().domain([10, 30]).range(['#D4E9FF', '#003966']);
    const populationScale = scaleSqrt().domain([404274, 9284275]).range([5, 23]);

    mapPaths.transition().duration(400).call(attrs, {
      fill: (d) => colorScale(d.properties.perc_fi_women_0_17),
      stroke: "#fff",      
    });

    mapPoints
      .transition()
      .duration(400)
      .call(attrs, {
        r: 0,
        scale: 1,
      }).call(styles, {
        opacity: 0
      });; 
  },

  frame5: (vizSelections) => {
    const { mapPaths, mapPoints } = vizSelections;
    const colorScale = scaleLinear().domain([10, 30]).range(['#D4E9FF', '#003966']);
    const populationScale = scaleSqrt().domain([404274, 9284275]).range([5, 23]);

    mapPaths.transition().duration(400).call(attrs, {
      fill: (d) => colorScale(getStateData(d, ['adult', 'old']).percentage),
      stroke: "#fff",      
    });

    mapPoints
      .transition()
      .duration(400)
      .call(attrs, {
        r: 0,
        scale: 1,
      }).call(styles, {
        opacity: 0
      });; 
  }



*/
