import styled from 'styled-components';
import PropTypes from 'prop-types';
import { device } from '../../utilities';

const Container = styled.div`
    display: flex;
    flex-direction: ${props => props.$reverseMobile ? 'column-reverse' : 'column'};
    gap: ${props => props.$gap};
    justify-content: ${props => props.$justify};
    align-items: ${props => props.$mobileAlign};

    @media ${props => props.$breakpoint} {
        flex-direction: row;
        align-items: ${props => props.$align};
    }
`;

const Pane = styled.div`
    display: flex;
    flex-direction: column;
    flex: ${props => props.$weight};
`;

function SplitScreen({ 
    children,
    leftWeight,
    rightWeight,
    gap,
    justify, 
    align,
    justified,
    mobileAlign = null,
    breakpoint,
    reversedColumn
}) {

    const [left, right] = children;

    return (
        <Container $reverseMobile={reversedColumn} $mobileAlign={mobileAlign ? mobileAlign : align} $gap={gap} $justify={justify} $align={align} $breakpoint={breakpoint}>
            {justified ? <Spaced left={left} right={right}/> : <Paned {...{leftWeight, rightWeight}} left={left} right={right}/> }
        </Container>
    );
}

function Paned({ leftWeight, rightWeight, left, right }) {
    return (
        <>
            <Pane $weight={leftWeight}>
                {left}
            </Pane>
            <Pane $weight={rightWeight}>
                {right}
            </Pane>
        </>
    )
}

function Spaced({ left, right }) {
    return (
        <>
            {left}
            {right}
        </>
    )
}

SplitScreen.propTypes = {
    // By default all props are optional. Please add the isRequired bool if you need them to be mandatory. 
    children: PropTypes.array.isRequired,
    leftWeight: PropTypes.number.isRequired,
    rightWeight: PropTypes.number.isRequired,
    gap: PropTypes.string.isRequired,
    justify: PropTypes.string.isRequired,
    justified: PropTypes.bool.isRequired,
    breakpoint: PropTypes.string.isRequired,
    reversedColumn: PropTypes.bool.isRequired
}

SplitScreen.defaultProps = {
    leftWeight: 1,
    rightWeight: 1,
    gap: '0px',
    justify: 'flex-start',
    justified: false,
    breakpoint: device.tablet,
    reversedColumn: false,
}

export default SplitScreen;
