import { useRef, useEffect, useState } from "react";
import { appendMapCanvas, removeMapCanvas } from "./d3-functions/mapCanvas";
import {
  appendRegionPaths,
  appendStatePaths,
} from "./d3-functions/mapParts";
import mapAnimFrames from "./d3-functions/mapAnimFrames";
import { usTopoFeatures } from "./data-js-files/usTopoFeatures";
import { stateTopoFeatures } from "./data-js-files/stateTopoFeatures";

import { scaleLinear } from "d3";
import {
  appendDiscColorLegend,
} from "./d3-functions/legends";

import TooltipWrapper from "./layout/tooltip";
import getMapPathEvents from "./d3-functions/mapPathEvents";

import { stateFips } from "./data-js-files/archive/state_fips";

const tempFormat = (d) => `${d}° C`;
const percFormat = (d) => `${round2Dec(d * 100, 1)}%`;
function round2Dec(num, digits) {
  const factor = 10 ** digits;
  return Math.round(num * factor) / factor;
}

export default function MapFrames({ state }) {
  const [vizSelections, setVizSelections] = useState(null);

  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);
  const [ttDisplay, setTtDisplay] = useState("none");
  const [ttData, setTtData] = useState({});
  const [metricFocus, setMetricFocus] = useState("Farm");

  const appRef = useRef();
  const stateSetters = {
    setPosX,
    setPosY,
    setTtDisplay,
    setTtData,
    setMetricFocus,
  };

  useEffect(() => {
    const mapCanvas = appendMapCanvas({
      parentRef: appRef.current,
      containerClassName: "mapContainer",
      containerWidth: "100%",
      viewBoxWidth: 975,
      viewBoxHeight: 640,
      topoFeatures: usTopoFeatures,
      stateTopoFeatures: stateTopoFeatures,
      stateSetters: stateSetters,
    });

    let farmCover = scaleLinear().domain([0, 1]).range(["#C6FF00", "#388E3C"]);

    const mapPaths = appendRegionPaths(
      mapCanvas,
      {
        groupId: "usRegionPaths",
      },
      {
        fill: (d) => {
          let cover = d.properties.farmCover.value;
          return cover ? farmCover(cover) : "#bdbdbd";
        },
        stroke: "#fff",
        "stroke-width": "0.2px",
      }
    );

    mapPaths
      .on("click", getMapPathEvents("click", stateSetters))
      .on("mouseover", getMapPathEvents("mouseover", stateSetters))
      .on("mouseout", getMapPathEvents("mouseout", stateSetters));

    const statePaths = appendStatePaths(
      mapCanvas,
      {
        groupId: "usStatePaths",
      },
      {
        stroke: "#fff",
        fill: "none",
        "stroke-width": "0px",
      }
    );

    const farmLegend = appendDiscColorLegend(mapCanvas, {
      positionX: 700,
      positionY: 25,
      colorScaleFunc: scaleLinear()
        .domain([0, 100])
        .range(["#C6FF00", "#388E3C"]),
      legendTitle: "Farm Cover (%)",
      scaleVal: 0.7,
    });

    const tempLegend1 = appendDiscColorLegend(mapCanvas, {
      positionX: 650,
      positionY: 25,
      colorScaleFunc: scaleLinear()
        .domain([-8, 0])
        .range(["#283593", "#F1F8E9"]),
      legendTitle: "Temperature Difference (°C)",
      scaleVal: 0.7,
    });

    tempLegend1.style("fill-opacity", 0);

    const tempLegend2 = appendDiscColorLegend(mapCanvas, {
      positionX: 755,
      positionY: 25,
      colorScaleFunc: scaleLinear()
        .domain([0, 8.5])
        .range(["#F1F8E9", "#B71C1C"]),
      legendTitle: "",
      scaleVal: 0.7,
    });

    tempLegend2.style("fill-opacity", 0);

    setVizSelections({
      mapPaths,
      statePaths,
      mapCanvas,
      tempLegend1,
      tempLegend2,
      farmLegend,
    });

    return () => removeMapCanvas(appRef.current);
  }, []);

  useEffect(() => {
    if (vizSelections) {
      const frame = `frame${state}`;

      if (state >= 0) {
        mapAnimFrames[frame](vizSelections);
      }
    }
  }, [state]);

  return (
    <>
      <div id="map-story-1">
        <div
          className="vizContain"
          ref={appRef}
          style={{ position: "relative" }}
        >
          <TooltipWrapper
            posX={posX}
            posY={posY}
            yOffset={50}
            width={200}
            display={ttDisplay}
            data={ttData}
            metricFocus={metricFocus}
          >
            <div className="toolTip-row">
              <strong className="Info-label">State: </strong>
              <span className="Info-value">{stateFips[ttData.id]}</span>
            </div>
            <div className="toolTip-row">
              <strong className="Info-label">County: </strong>
              <span className="Info-value">{ttData.countyName}</span>
            </div>
            <div
              className="toolTip-row"
              style={{
                opacity:
                  metricFocus === "Both" || metricFocus === "Temp" ? 1 : 0.5,
              }}
            >
              <strong className="Info-label">Forecast Temp. : </strong>
              <span className="Info-value">
                {tempFormat(round2Dec(ttData.tempForecast, 1))}
              </span>
            </div>
            <div
              className="toolTip-row"
              style={{
                opacity:
                  metricFocus === "Both" || metricFocus === "Farm" ? 1 : 0.5,
              }}
            >
              <strong className="Info-label">Farm Cover: </strong>
              <span className="Info-value">{percFormat(ttData.farmCover)}</span>
            </div>
          </TooltipWrapper>
        </div>
      </div>
    </>
  );
}
