import { ChapterSection, ChartSection, ViewWrapper, TextContainer, BorderedText, ContentContainer } from "../components/wrappers";

import Landing from "../components/view-specific/landing/landing";

import { useEffect, useState } from 'react';

import { climateMap, insecureMap } from "../data";

import MapFrames from "../components/MapFrames";
import FoodMapFrames from "../components/Food Map";

import IOList from "../components/layout/io_list";
import IoRenderer from "../components/general/ioRenderer";
import StickyVis from "../components/layout/stickyVis";
import RenderFooter from "../components/general/renderFooter";
import ChapterTitle from "../components/view-specific/chapters/chapterTitle";

import Dropdown from "../components/general/dropdown";
import { orgTypes } from "../data";
import Statistic from "../components/general/statistic";

import HeatMap from "../components/HeatMap";
import Overlay from "../components/layout/overlay";
import OverlayContent from "../components/layout/overlayContent";

import ReactGA from "react-ga4";

function Home() {

    const [mapFrameIndex, setMapFrameIndex] = useState(0);
    const [foodMapFrameIndex, setFoodMapFrameIndex] = useState(0);
    const [orgType, setOrgType] = useState('Food Banks & Pantries');
    const [triggerOverlay, setOverlayTrigger] = useState(false);
    const [overlayContent, setOverlayContent] = useState(null);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/", title: "Food, Farming & Climate Change" });
    }, []);

    return (
        <ViewWrapper>

            <Landing 
                title={'Food, Farming'}
                desc="Despite national and international efforts to bridge the gender gap, Mexican women remain vulnerable to malnutrition (hunger) and food insecurity. This project explores the issue in an interactive data story, highlighting the scale of the problem and its relationship with other SDGs that impact the lives of Mexican women."
            />

            <ChapterSection>
                <TextContainer>
                    <BorderedText>
                        <p>Rising temperatures, extreme heat, drought, wildfire on rangelands, and heavy downpours are expected to increasingly disrupt agricultural productivity in the United States. Expected increases in challenges to livestock health, declines in crop yields and quality, and changes in extreme events in the United States  and abroad threaten rural livelihoods, sustainable food security, and price stability.</p>
                        <p><a className='link' href='https://nca2018.globalchange.gov/' target="_blank" rel='noreferrer'>National Climate Assessment, 2018</a></p>
                    </BorderedText>
                </TextContainer>
            </ChapterSection>

            <ChapterSection>
                <TextContainer>
                    <BorderedText>
                        <p>July, 2023 was the Earth’s hottest month on record. Heat waves have steadily increased in frequency and intensity since the 1960’s according to the <a style={{color: 'unset'}} target="_blank" rel="noreferrer" href="https://www.climate.gov/news-features/understanding-climate/2022-global-temperature-recap">National Oceanic and Atmospheric Administration</a>. <b>Rising temperatures impact farm yields and the livelihoods of farmers. At the same time they can exacerbate food insecurity in those communities</b> as people are less able to work and safely travel to secure food. This site will explore the relationships between predicted climate change, farm production and food insecurity in the United States.</p>
                    </BorderedText>
                </TextContainer>
            </ChapterSection>

            <ChapterSection>
                <TextContainer>
                    <ChapterTitle number={1} title="How will farming regions be impacted by climate change?" />
                </TextContainer>
            </ChapterSection>

            <ChapterSection>
                <StickyVis
                    source={<span><a href='https://datacommons.org/tools/map#%26sv%3DMaxTemp_Daily_GaussianMixture_5PctProb_Greater_Atleast1DayAYear_CMIP6_Ensemble_SSP245%26pc%3D0%26denom%3DCount_Person%26pd%3Dcountry%2FUSA%26ept%3DCounty' target="_blank" rel="noreferrer">NASA via Data Commons</a> , <a href='https://datacommons.org/tools/map#%26sv%3DArea_Farm%26pc%3D0%26denom%3DCount_Person%26pd%3Dcountry%2FUSA%26ept%3DCounty' target="_blank" rel="noreferrer">USDA via Data Commons</a></span>}
                    r_weight={2.5}
                >
                    <IOList fill='var(--blue)' margin='1000px 0px' data={climateMap} element={IoRenderer} callback={setMapFrameIndex}                     source={<span><a href='https://datacommons.org/tools/map#%26sv%3DMaxTemp_Daily_GaussianMixture_5PctProb_Greater_Atleast1DayAYear_CMIP6_Ensemble_SSP245%26pc%3D0%26denom%3DCount_Person%26pd%3Dcountry%2FUSA%26ept%3DCounty' target="_blank" rel="noreferrer">NASA via Data Commons</a> , <a href='https://datacommons.org/tools/map#%26sv%3DArea_Farm%26pc%3D0%26denom%3DCount_Person%26pd%3Dcountry%2FUSA%26ept%3DCounty' target="_blank" rel="noreferrer">USDA via Data Commons</a></span>}/>
                    <MapFrames state={mapFrameIndex}/>
                </StickyVis>
            </ChapterSection>

            <ChapterSection>
                <TextContainer>
                    <ChapterTitle number={2} title="Are farming regions vulnerable to food insecurity?" />
                </TextContainer>
            </ChapterSection>

            <ChapterSection>
                <TextContainer>
                    <BorderedText>
                        <p>According to a <a target="_blank" style={{color: 'unset'}} rel="noreferrer" href="https://www.urban.org/research/publication/inflation-squeezed-family-budgets-food-insecurity-increased-between-2021-and-2022">study by the Urban Institute</a>, <b>the rate of food insecurity in the United States was 24.6% in 2022. This was a jump of nearly 5 percentage points from 2021</b>, and was caused by significant food price inflation and the expiration of COVID-19 pandemic aid. Almost 1 in 6 adults reported receiving free groceries or meals.</p>
                    </BorderedText>
                </TextContainer>
            </ChapterSection>

            <ChapterSection><ContentContainer>
                    <Statistic stat={'1 in 6'} desc='adults reported receiving free groceries or meals according to a study conducted by the Urban institute' fill={'#00A6A2'}/>
                </ContentContainer></ChapterSection>

            <ChapterSection>
                <StickyVis
                    source={<a href='https://datacommons.feedingamerica.org/tools/map#%26sv%3DCount_Person_FoodInsecure%26pc%3D0%26denom%3DCount_Person%26pd%3Dcountry%2FUSA%26ept%3DCounty' target="_blank" rel="noreferrer">Feeding America via Data Commons</a>}
                    r_weight={2.5}
                >
                    <IOList fill='var(--blue)' margin='1000px 0px' data={insecureMap} element={IoRenderer} callback={setFoodMapFrameIndex} source={<a href='https://datacommons.feedingamerica.org/tools/map#%26sv%3DCount_Person_FoodInsecure%26pc%3D0%26denom%3DCount_Person%26pd%3Dcountry%2FUSA%26ept%3DCounty' target="_blank" rel="noreferrer">Feeding America via Data Commons</a>}/>
                    <FoodMapFrames state={foodMapFrameIndex}/>
                </StickyVis>
            </ChapterSection>

            <ChapterSection>
                <TextContainer>
                    <BorderedText>
                        <p>Although all countries will face the challenge of climate change, some communities will experience greater impacts than others.</p>
                        <p><b>According to the World Bank, about 80% of the global population most at risk from crop failures and hunger from climate change are in Sub-Saharan Africa, South Asia, and Southeast Asia</b>, where farming families are disproportionally poor and vulnerable.</p>
                        <p><a className='link' href='https://www.worldbank.org/en/news/feature/2022/10/17/what-you-need-to-know-about-food-security-and-climate-change' target="_blank" rel='noreferrer'>The World Bank, 2022</a></p>
                    </BorderedText>
                </TextContainer>
            </ChapterSection>

            <ChapterSection>
                <TextContainer>
                    <BorderedText>
                        <p>Nonetheless it is clear that <b>climate change and food insecurity will be acutely felt in America’s food producing regions</b>. Fortunately there are many nonprofits small and large working in these regions to fill the meal gap.</p>
                    </BorderedText>
                </TextContainer>
            </ChapterSection>

            <ChapterSection>
                <TextContainer>
                    <h2 className="heading_orgs">Find organizations working in your state</h2>
                    <p className="subheading_orgs">Click on your state to browse organizations</p>
                </TextContainer>
                <TextContainer>
                    <Dropdown options={orgTypes} title='Organization Types' stateHandler={setOrgType} value={orgType}/>
                </TextContainer>
                <ChartSection>
                    <HeatMap {...{orgType, setOverlayTrigger, setOverlayContent}} />
                </ChartSection>

                <Overlay trigger={triggerOverlay} triggerFunction={setOverlayTrigger}>
                    <OverlayContent content={overlayContent} {...{orgType}}/>
                </Overlay>
            </ChapterSection>

            <ChapterSection>
                <TextContainer>
                    <BorderedText>
                        <p>Announcing a White House conference on hunger and health, <a href='https://www.whitehouse.gov/briefing-room/statements-releases/2022/05/04/white-house-announces-conference-on-hunger-nutrition-and-health-in-september/' target="_blank" rel="noreferrer">Ambassador Susan E. Rice said,</a> <b>“no one should have to wonder where their next meal will come from. We must take bold steps now—with government, the private sector, nonprofits, and communities working together—to build a healthier future for every American.”</b> <br/><br/> Climate change will add to this challenge for America’s farming communities.</p>
                    </BorderedText>
                </TextContainer>
            </ChapterSection>

            <RenderFooter/>
        </ViewWrapper>
    );
  }

  export default Home;
