import styled from 'styled-components';
import ReactIntersectionObserver from "./intersectionObserver";
import PropTypes from 'prop-types';

const Container = styled.div`
    position: relative;
    margin: ${props => props.$margin};
`;

function IOList({ data, element: Element, margin, callback, fill, source }) {
    return (
        data.map((data, index) => {
            return (
                <Container key={index + 'io'} $margin={margin}>
                    <ReactIntersectionObserver key={index} callback={() => callback(index)}>
                        <Element {...{ data, fill, source }} />
                    </ReactIntersectionObserver>
                </Container>
            )
        })
    );
}

IOList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.node).isRequired,
    margin: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
}

IOList.defaultProps = {
    margin: '425px 0px',
    callback: function () { console.log('callback') }
}

export default IOList;