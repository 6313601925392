import { select } from "d3";

export default function getMapPathEvents(type, stateSetters, storyIndex = 1) {
  //
  // the state setters that the event handlers will need access to
  const { setPosX, setPosY, setTtDisplay, setTtData } = stateSetters;

  const events = {
    mouseover: function (e) {
      // getting target selection and datum
      const currSelection = select(this);
      const currDatum = currSelection.datum();
      const stateId = currDatum.id.slice(0, 2);
      const popInsecure = currDatum.properties.popInsecure.value;
      const pop = currDatum.properties.popEstimate.value;

      const statePathSelector =
        storyIndex === 1
          ? `div#map-story-1 path.state#id${stateId}`
          : `div#map-story-2 path.state#id${stateId}`;

      select(statePathSelector).raise().attr("stroke", "purple");

      // making visual changes
      currSelection
        .raise()
        .style("stroke", "#212121")
        .style("stroke-width", "2px");

      // retrieving data to display
      const dataToShow = {
        id: stateId,
        stateId: currDatum.id.slice(0, 2),
        countyName: currDatum.properties.name,
        farmCover: currDatum.properties.farmCover.value,
        tempForecast: currDatum.properties.tempForecastCMIP6_245.value,
        foodInsRate: popInsecure / pop,
      };

      // setting states bases on event data and current Datum
      setPosX(e.clientX);
      setPosY(e.clientY);
      setTtData(dataToShow);
      setTtDisplay("block");
    },

    mouseout: function () {
      const currSelection = select(this);
      const currDatum = currSelection.datum();
      const stateId = currDatum.id.slice(0, 2);
      // making visual changes of targer selection
      select(this).style("stroke", "#fff").style("stroke-width", "0.2px");

      const statePathSelector =
        storyIndex === 1
          ? `div#map-story-1 path.state#id${stateId}`
          : `div#map-story-2 path.state#id${stateId}`;
      select(statePathSelector).attr("stroke", "white");
      // set state
      setTtDisplay("none");
    },

    click: function() {
      const selection = select(this);
      const data = selection.datum();
      window.open(`https://datacommons.techsoup.org/topic/foodsecurity/geoId/${data.id}`);
    }
  };

  return events[type];
}
