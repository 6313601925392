import styled from "styled-components";
import layout from "../../css-modules/layouts.module.css";
import { applyClasses } from "../../utilities";
import Navbar from "./navbar";
import { gsap, Power2 } from "gsap";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useContext } from 'react';
import { AppContext } from '../contexts';

const ChapterHeading = styled.h3`
  font-family: "Georgia", serif;
  font-weight: 400;
  font-size: 1.8em;
  margin: 1em 0em;
`;

const ClickHandler = styled.div`
    position: relative;
    width: fit-content;
    cursor: pointer;
    color: #000;
    text-align: center;
    transition: 0.3s ease;

    &:before {
        content: '';
        position: absolute;
        top: 90%;
        left: calc(50% - 10px);
        display: flex;
        width: 20px;
        height: 5px;
        background-color: #00bab5;
        z-index: -1;
        transform: scale(0);
        transition: 0.25s ease;
    }

    &:hover {
        color: '#444';

        &:before {
            transform: scale(1);
        }
    }
`

const MenuItemContainer = styled.div`
    transform: translateY(50px);
    filter: blur(5px);
    opacity: 0;
`;

function ChapterTitle({ title }) {
  return <ChapterHeading>{title}</ChapterHeading>;
}

function MenuItem({ number, title, handler, index, transition }) {

  const listNode = useRef();
  const { setMenuOpen } = useContext(AppContext);

  useEffect(() => {
    let listSettings = transition
      ? { y: 0, opacity: 1, filter: 'blur(0px)', duration: 0.8, ease: Power2.easeInOut, delay: 0.2 * (index + 1) }
      : { y: 50, opacity: 0, filter: 'blur(5px)', duration: 0.7 }; 

    gsap.to(listNode.current, listSettings);

  }, [transition, index]);

  const navigate = useNavigate();

  const clickHandler = (prop) => {
      if (typeof(prop) === 'string') {
          navigate(prop);
          setMenuOpen(false);
      }
      else {
          handler();
      }
  }

  return (
    <MenuItemContainer ref={listNode}>
      <div className={applyClasses(layout, ["flexed-column", "align-center"])}>
        <ClickHandler onClick={() => clickHandler(handler)}>
          {/*title !== 'About the project' && <ChapterNumber color="inherit" number={number} /> */}
          <ChapterTitle {...{ title }} />
        </ClickHandler>
      </div>
    </MenuItemContainer>
  );
}

function MenuList({ list, transition }) {
  return (
    <>
      {list.map((item, index) => {
        return (
          <MenuItem
            key={"menuItem" + index}
            index={index}
            transition={transition}
            number={index + 1}
            title={item.title}
            handler={item.handler}
          />
        );
      })}
    </>
  );
}

const MenuContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  transform: translateX(100vw);
  z-index: 5;
  background: ${(props) => props.$fill};
  border: 10px #00bab5 solid;
  box-sizing: border-box;
  height: 100dvh;
  width: 100%;
`;

const ListContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

function Menu({ list, fill, transition }) {
  const container = useRef();

  useEffect(() => {
    let settings = transition
      ? { x: 0, duration: 0.9, ease: Power2.easeInOut }
      : { x: '100vw', duration: 0.8 };

    gsap.to(container.current, settings);
  }, [transition]);

  return (
    <MenuContainer ref={container} $fill={fill}>
      <Navbar menu/>
      <ListContainer>
        <MenuList {...{ list, transition }}/>
      </ListContainer>
    </MenuContainer>
  );
}

export default Menu;
