import { geoPath } from "d3";
import { geoAlbersUsaPr } from "../../utilities";
/*projections
  you will have to adjust projection parameters (center and scale) 
  depending on the map you are creating
*/
const projection = geoAlbersUsaPr().scale(1300).translate([487.5, 305]);

// geoPath functions computed from projections
const geoPathGen = geoPath(projection);

export { projection, geoPathGen };
