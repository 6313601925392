import styled from 'styled-components';
import { device } from '../../../utilities';

const Heading = styled.h1`
    font-family: 'Georgia-Italic', sans-serif;
    margin: 0px;
    color: #000;
    line-height: 90%;
    font-weight: 400;
    letter-spacing: -0.5rem;
    font-size: clamp(3.25rem, 3rem + 5.5vw, 7rem);
    z-index: 2;
    position: relative;

    @media (max-width: 450px) {
        font-size: 3.75rem;
        letter-spacing: -0.3rem;
    }


    @media (max-width: 350px) {
        font-size: 3rem;
    }

    @media ${device.fold} {
        span {
            display: block;
            margin-left: -1em;
            text-indent: 1.8em;
        }
    }

    @media screen and (max-width: 800px) {
        align-self: flex-start;
    }

    @media screen and (min-height: 900px) and (min-width: 1800px) {
        font-size: clamp(4.25rem, 6rem + 6.75vw, 8.5rem);
    }
`;

function SerifTitle({
    title,
    innerRef
}) {

    return (
        <Heading ref={innerRef}>{title}</Heading>
    )
}

export default SerifTitle;