import styled from 'styled-components';
import PropTypes from 'prop-types';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Container = styled.div`
    position: relative;
    width: 100%;
    max-width: 300px;
`

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

function Dropdown ({ options, stateHandler, title, value }) {

    return (
        <Container>
            <FormControl varient='standard' fullWidth>
                <InputLabel id="demo-simple-select-label">{title}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label={title}
                    onChange={(e) => stateHandler(e.target.value)}
                    MenuProps={MenuProps}
                >
                    {options.map((item, index) => {
                        return <MenuItem key={index + 'dropdown'} value={item.value || item}>{item.label || item}</MenuItem>
                    })}
                </Select>
            </FormControl>

        </Container>
    )
};

Dropdown.propTypes = {
    options: PropTypes.array.isRequired,
    stateHandler: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
}

Dropdown.defaultProps = {
    options: ['ABC', 'DEF', 'IJK'],
    stateHandler: (d) => console.log(d),
    title: 'Options',
    defaultValue: 'ABC'
}

export default Dropdown;