import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'
import { gsap } from 'gsap';
import { MdClose } from "react-icons/md";

function Overlay ({ children, color, trigger, triggerFunction }) {
    const element = useRef();

    useEffect(() => {
        if (trigger) {
            element.current.style.visibility = 'visible';
            gsap.to(element.current, {
                opacity: 1,
                y: 0,
                duration: 0.3
            });
        }
        else {
            gsap.to(element.current, {
                opacity: 0,
                y: 35,
                duration: 0.25,
                onComplete: () => element.current.style.visibility = 'hidden'
            });
        } 
    }, [trigger]);

    return (
        <Container ref={element}>
            <Background onClick={() => triggerFunction(false)}/>
            <Content $color={color}>
                {children}
                <CloseButton>
                    <MdClose onClick={() => triggerFunction(false)}/>
                </CloseButton>
            </Content>
        </Container>
    );
}

const Container = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100dvh;
    border-radius: 15px;
    z-index: 4;
    opacity: 0;
    transform: translateY(100vh);
`

const Background = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 2;
    cursor: pointer;
    background-color: rgba(255,255,255,0.5);
`

const CloseButton = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 30px;
    padding: 0.5em;
    cursor: pointer;
`

const Content = styled.div`
    position: absolute;
    top: 8dvh;
    bottom: 8dvh;
    left: 50%;
    transform: translateX(-50%);
    width: 45vw;
    min-width: 300px;
    height: 84dvh;
    background-color: '#fff';
    border: 10px solid ${props => props.$color};
    border-radius: 0px;
    background-color: #fff;
    z-index: 4;
    padding: 1em 3em;
    box-sizing: border-box;
    overflow-y: scroll;

    @media screen and (max-width: 850px){
        width: 62.5vw;
    }

    @media screen and (max-width: 550px){
        width: 75vw;
    }
`

Overlay.propTypes = {
    color: PropTypes.string.isRequired,
    trigger: PropTypes.bool.isRequired
}

Overlay.defaultProps = {
    color: '#00bab5',
    children: <div></div>,
    trigger: false
}

export default Overlay;