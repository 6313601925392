import { useRef, useEffect, useState } from "react";
import { appendMapCanvas, removeMapCanvas } from "./d3-functions/mapCanvas";
import {
  appendRegionPaths,
  appendStatePaths,
} from "./d3-functions/mapParts";
import { usTopoFeatures } from "./data-js-files/usTopoFeatures";
import { stateTopoFeatures } from "./data-js-files/stateTopoFeatures";

import { scaleLinear } from "d3";
import {
  appendDiscColorLegend,
} from "./d3-functions/legends";
import getMapPathEvents from "./d3-functions/mapPathEvents";

import mapAnimFrames from "./d3-functions/foodMapAnimFrames";
import TooltipWrapper from "./layout/tooltip";


import { stateFips } from "./data-js-files/archive/state_fips";

const percFormat = (d) => `${round2Dec(d * 100, 1)}%`;
function round2Dec(num, digits) {
  const factor = 10 ** digits;
  return Math.round(num * factor) / factor;
}

export default function FoodMapFrames({ state }) {
  const [vizSelections, setVizSelections] = useState(null);
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);
  const [ttDisplay, setTtDisplay] = useState("none");
  const [ttData, setTtData] = useState({});

  const appRef = useRef();
  const stateSetters = { setPosX, setPosY, setTtDisplay, setTtData };

  useEffect(() => {
    const mapCanvas = appendMapCanvas({
      parentRef: appRef.current,
      containerClassName: "mapContainer",
      containerWidth: "100%",
      viewBoxWidth: 975,
      viewBoxHeight: 610,
      topoFeatures: usTopoFeatures,
      stateTopoFeatures: stateTopoFeatures,
      stateSetters: stateSetters,
    });

    let colScaleInsecure = scaleLinear()
      .domain([0, 0.35])
      .range(["#D4E9FF", "#003966"]);

    const mapPaths = appendRegionPaths(
      mapCanvas,
      {
        groupId: "usRegionPaths",
      },
      {
        fill: (d) => {
          let props = d.properties;
          let rate = null;
          if (props.popInsecure.value && props.popEstimate.value) {
            rate = props.popInsecure.value / props.popEstimate.value;
          }
          return rate ? colScaleInsecure(rate) : "#bdbdbd";
        },
        stroke: (d) => "#fff",
        "stroke-width": "0.35px",
        "fill-opacity": 1,
      }
    );

    mapPaths
      .on("click", getMapPathEvents("click", stateSetters, 2))
      .on("mouseover", getMapPathEvents("mouseover", stateSetters, 2))
      .on("mouseout", getMapPathEvents("mouseout", stateSetters, 2));

    const statePaths = appendStatePaths(
      mapCanvas,
      {
        groupId: "usStatePaths",
      },
      {
        stroke: "#fff",
        fill: "none",
        "stroke-width": "0px",
      }
    );

    appendDiscColorLegend(mapCanvas, {
      positionX: 700,
      positionY: 25,
      colorScaleFunc: scaleLinear()
        .domain([0, 35])
        .range(["#D4E9FF", "#003966"]),
      legendTitle: "Food Insecurity (%)",
      scaleVal: 0.7,
    });

    setVizSelections({ mapPaths, statePaths });

    return () => removeMapCanvas(appRef.current);
  }, []);

  useEffect(() => {
    if (vizSelections) {
      const frame = `frame${state % 3}`;

      if (state >= 0) {
        mapAnimFrames[frame](vizSelections);
      }
    }
  }, [state]);

  return (
    <>
      <div id="map-story-2">
        <div className="vizContain" ref={appRef}></div>
        <TooltipWrapper
          posX={posX}
          posY={posY}
          yOffset={50}
          width={200}
          display={ttDisplay}
          data={ttData}
        >
          <div className="toolTip-row">
            <strong className="Info-label">State: </strong>
            <span className="Info-value">{stateFips[ttData.id]}</span>
          </div>
          <div className="toolTip-row">
            <strong className="Info-label">County: </strong>
            <span className="Info-value">{ttData.countyName}</span>
          </div>
          <div
            className="toolTip-row"
          >
            <strong className="Info-label">% Food Insecure: </strong>
            <span className="Info-value">{percFormat(ttData.foodInsRate)}</span>
          </div>
          <div
            className="toolTip-row"
          >
            <strong className="Info-label">Farm Cover: </strong>
            <span className="Info-value">{percFormat(ttData.farmCover)}</span>
          </div>
        </TooltipWrapper>
      </div>
    </>
  );
}
