import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    align-items: ${(props) => props.$align};
    justify-content: ${(props) => props.$align};
    cursor: pointer;

    font-size: 0.85em;

    &:hover {
        img {
            transform: scale(1.1);
        }
    }
`


function Powered({ align }) {
    return (
        <Container $align={align || 'center'} onClick={() => window.open('https://datacommons.org/')}>
            <p>Powered by Google's Data Commons</p>
        </Container>
    )
}

export default Powered;